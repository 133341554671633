import comp from '../components/barrel';
import svg from '../js/svg';
import config from '../js/config';
import O from '../js/core';

export default {
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    this.recipe.methods.push(await O.mPOST('wills/get-support-docs', {application_uuid: this.application_uuid}));
    this.recipe.methods.push(await O.mPOST('communal/file-categories', {}));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const supportDocs = data['wills/get-support-docs'].data;
    const fileCategories = data['communal/file-categories'].data;
    this.fileCategories = fileCategories.filter(item => 
      ['APPLICATION FORM', 'AMMENDED APPLICATION FORM', 'SUPPORT DOCUMENT'].includes(item.long_text)
    );
    await this.buildListDiv(supportDocs);
  },
  async buildUploadModal() {
    const popup = await this.uploadModal();
    document.body.prepend(popup);
  },
  async uploadModal() {
    // const uploadDocumentDiv = U.id('uploadDocumentDiv');
    const uploadDocumentDiv = U.divAtt('div');

    const select = await comp.select_v2({
      elID: 'categoryList',
      ddData: this.fileCategories,
      valueDataName: 'file_category_uuid',
      textDataName: 'short_text',
      labelName: 'Document Category',
      requiredYN: true,
    });

    const fileInput = await comp.inputFile({elID: 'fileUpload', labelName: 'Document:', requiredYN: true});

    uploadDocumentDiv.appendChild(select);
    uploadDocumentDiv.appendChild(fileInput);

    const div = `
		  <div id="uploadModal" class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black">
			<div id="uploadDocumentDiv" class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
			  <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
				<!-- Modal Content -->
				<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
				  <div class="text-center">
					<!-- Heading -->
					<h2 class="text-2xl font-semibold mb-4">Upload Document</h2>
				  </div>
	  
				  <div class="text-left">
					${uploadDocumentDiv.outerHTML}
				  </div>
				</div>
	  
				<!-- Footer Buttons -->
				<div class="bg-gray-50 px-4 py-3 flex md:justify-end justify-center sm:px-6">
				  <button class="btn-outline mr-3" onclick="U.closeModal('uploadModal')">Cancel</button>
				  <button id="modal-btn" class="btn-primary" onclick="thsTab.uploadDocument()">Upload Document</button>
				</div>
			  </div>
			</div>
		  </div>
		`;

    return await U.createHTMLElementFromString(div);
  },
  async buildListDiv(data) {
    U.hide('documentListDiv');

    const documentListDiv = U.id('documentListDiv');
    documentListDiv.innerHTML = '';

    const table = U.divAtt('table', 'class', 'w-full');

    // HEADER
    const thead = U.divAtt('thead', 'class', 'w-full');
    const headRow = thead.insertRow();

    const h1 = headRow.insertCell(0);
    h1.innerHTML = 'Date';
    const h2 = headRow.insertCell(1);
    h2.innerHTML = 'File Name';
    const h3 = headRow.insertCell(2);
    h3.innerHTML = 'Uploaded By';
    const h4 = headRow.insertCell(3);
    h4.innerHTML = 'Category';
    const h5 = headRow.insertCell(4);
    h5.innerHTML = '';
    thead.appendChild(headRow);
    table.appendChild(thead);

    // Body
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.length) {
      data.forEach(async (row) => {
        const willRow = tbody.insertRow();
        const r1 = willRow.insertCell(0);
        r1.innerHTML = await U.formatDate(row.created_date);
        const r2 = willRow.insertCell(1);
        r2.innerHTML = row.file_name;
        const r3 = willRow.insertCell(2);
        r3.innerHTML = `${row.f_name} ${row.s_name}`;
        const r4 = willRow.insertCell(3);
        r4.innerHTML = row.file_category_text;
        const h5 = willRow.insertCell(4);
        const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
        const linkSVG = svg.download(download_onclick);
        h5.appendChild(linkSVG);
      });
      U.unhide('documentListDiv');
    }

    documentListDiv.appendChild(table);
  },
  async uploadDocument() {

    let isValid = await U.validateForm('uploadDocumentDiv');

    if (!isValid) {
      return;
    }

    const formData = new FormData();
    formData.append('uploadFile', U.id('fileUpload').files[0]);
    formData.append('application_uuid', this.application_uuid);
    formData.append('file_category_uuid', U.id('categoryList').value);

    const hdrs = await O.getAuthHdrs();

    // Send the form data using fetch
    try {
      const response = await fetch(`${config.apiURL}wills/upl-support-doc`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const result = await response.json();
      this.render();
    } catch (error) {
      console.error('Error uploading files:', error);
      O.handleFetchError(error);
    } finally {
		U.closeModal('uploadModal');
	}
  },
  async downloadFile(file_uuid) {
    const vars = {};
    vars.file_uuid = file_uuid;

    O.PREP([await O.mPOST('files/get-file-url', vars)])
      .then(async (res) => {
        window.open(res['files/get-file-url'].data.signedUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
