import U from './utils';
import config from './config';
import Cls from '../classes/barrel';
import pages from '../pages/barrel';
import Ex from '../extensions/barrel';
import comp from '../components/barrel';

export default {
  async PREP(rec, oldVars, loaderMsg) {
    comp.loader(1, loaderMsg || '');

    const vars = typeof oldVars === 'string' ? await U.urlToObj(oldVars) : oldVars || {};

    const hdrs = await this.getAuthHdrs();
    let recipe = rec;

    if (Array.isArray(rec)) {
      const methods = rec;
      return this.exRequest({methods, hdrs});
    } else {
      if (typeof rec == 'string') {
        recipe = await this.getRecipe();
        recipe.page = rec;
      }
      if (pages.hasOwnProperty(recipe.page)) {
        await this.createPage({recipe, hdrs, vars});
      } else {
        console.log('404 - Page not found', recipe.page);
        comp.loader(0);
        return '404 - Page not found;';
      }
    }
    comp.loader(0);
  },
  async createPage({recipe, hdrs, vars}) {
    const newClass = new Cls.BasePage({recipe, vars, hdrs});
    newClass.pageTitle = recipe.page;
    const methods = Ex[recipe.page];
    const newPage = {...newClass, ...methods};

    await newPage.prepare();
    newPage.tabs = newPage.tabsYN ? await this.getTabs(recipe.page) : [];
    pgList[recipe.page] = newPage;
    if (recipe.pageType === 'main') {
      history.pushState(JSON.stringify(newPage), '', `${recipe.page}`);
    }

    await this.renderPage(newPage, recipe);

    if (recipe.pageType === 'tab') {
      const parseState = await JSON.parse(history.state);
      if (!parseState.tabs[recipe.page].subMenu) {
        return;
      }
      // if (parseState.tabs[recipe.page].subMenu.length) {
      //   this.changeSubTab(parseState.tabs[recipe.page].subMenu[0].html_text);
      //   return;
      // } @STeph
    }
  },
  async renderPage(newPage, recipe) {
    U.id('shadowDiv').innerHTML = pages[recipe.page];

    switch (recipe.pageType) {
      case 'main':
        window.thsPage = newPage;
        break;
      case 'tab':
        window.thsTab = newPage;
        break;
      case 'subTab':
        window.thsSubTab = newPage;
        break;
      default:
        console.log('Page type not recognised');
    }

    await newPage.render();
    const rendered = U.id('shadowDiv').innerHTML;
    U.id('shadowDiv').innerHTML = '';
    U.id(`${recipe.div}`).innerHTML = rendered;
  },
  // Fetch calls
  async exRequest({methods, hdrs}) {
    try {
      const data = methods.length ? await this.fetchMethods({methods, hdrs}) : {};
      comp.loader(0);
      return data;
    } catch (err) {
      comp.loader(0);
      //@STEPH error handling here
      this.handleFetchError(err);
      throw err;
    }
  },
  async fetchMethods({methods, hdrs}) {
    if (!methods.length) {
      return 'Method Array Empty';
    }

    const data = await Promise.all(
      methods.map(async (method) => {
        try {
          const res = await this.dataSwitch({method, hdrs});

          const statusCodeKey = `${method.method}_SC`;
          const statusTextKey = `${method.method}_ST`;

          return {
            [statusCodeKey]: res.statusCode,
            [statusTextKey]: res.statusText,
            [method.method]: res.data,
          };
        } catch (error) {
          throw error;
        }
      })
    );

    return Object.assign({}, ...data);
  },
  async dataSwitch({method, hdrs}) {
    try {
      switch (method.reqType) {
        case 'POST':
          return await this.postMethod({method, hdrs});
        case 'GET':
          return await this.getMethod({method, hdrs});
        default:
          throw new Error('Unsupported request type');
      }
    } catch (error) {
      if (error.status === 401) {
        const refresh = await this.refreshTokens();
        if (refresh) {
          // Retry the original request after refreshing tokens
          return await this.dataSwitch({method, hdrs});
        } else {
          throw 'Please log in again';
        }
      } else {
        throw error;
      }
    }
  },
  async postMethod({method, hdrs}) {
    let body = method.vars || {};

    body.user_uuid = hdrs.user_uuid ? hdrs.user_uuid : '';

    try {
      const response = await fetch(method.endPoint + method.method, {
        method: method.reqType,
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const parsedData = await this.handleResponse(response);

      return {
        data: parsedData,
        statusCode: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      throw error;
    }
  },
  async getMethod({method, hdrs}) {
    const tempVars = method.vars ? method.vars : {};

    if (typeof tempVars !== 'object') {
      throw new Error('System Error');
    }

    const vars = Object.entries(tempVars)
      .map(([key, value]) => `${key}=${value}`)
      .join('/');

    const url = `${method.endPoint}${method.method}?${vars}`;
    try {
      const response = await fetch(url, {
        method: method.reqType,
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
          'Content-Type': 'application/json',
        },
      });

      const parsedData = await this.handleResponse(response);

      return {
        data: parsedData,
        statusCode: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async handleResponse(response) {
    if (response.ok) {
      const res = await response.json();
      this.handleFetchFeedback({status: response.status, message: res.status});
      return res;
    } else {
      const errorData = await response.json();
      throw {status: response.status, data: errorData};
    }
  },
  async handleFetchError(error) {
    switch (true) {
      case error.status >= 400 && error.status < 500:
        comp.errorBox(error.data.status, error.data.data.error);
        break;
      case error.status >= 500 && error.status < 600:
        comp.errorBox('Error', 'There was an error with the server. Please contact your system administrator.');
        break;
      default:
        comp.errorBox('Error', 'There was a system error. Please contact your system administrator.');
    }
    comp.loader(0);
  },
  async handleFetchFeedback({status, message}) {
    switch (true) {
      case status === 209:
        comp.toast(message);
        break;
    }
  },
  async getAuthHdrs() {
    const user_uuid = await O.getCookie('ewttgpvwwkf');
    const token = await O.getCookie('vvjsdugfie');
    const language_id = await O.getCookie('mbohvbhfje');

    const vars = {};
    vars.token = token.value;

    if (!token.value) {
      await this.refreshTokens();
      const token2 = await O.getCookie('vvjsdugfie');
      vars.token = token2.value;
    }

    vars.user_uuid = user_uuid.value;
    vars.language_id = language_id.value;

    return vars;
  },
  async popstate(page) {
    const parsePage = JSON.parse(page);

    if (parsePage.pageTitle === 'Login') {
      return location.reload();
    }

    if (U.id('mainNavigation')) {
      if (!U.id('mainNavigation').hasChildNodes()) {
        await comp.navigation();
      }
    }

    const methods = Ex[parsePage.pageTitle];
    const newPage = {...parsePage, ...methods};

    pgList[parsePage.pageTitle] = pgList[parsePage.pageTitle] ? pgList[parsePage.pageTitle] : newPage;

    await this.renderPage(pgList[parsePage.pageTitle], parsePage.recipe);
  },
  async getRecipe() {
    return new Cls.Recipe();
  },
  async logRes(res) {
    let dataObj = {};
    let statusCodes = {};
    let statusTexts = {};

    for (let item in res.data) {
      let split = item.split('_');
      if (split.length > 1) {
        if (split[1] === 'SC') {
          statusCodes[item] = res.data[item];
        } else {
          statusTexts[item] = res.data[item];
        }
      } else {
        dataObj[item] = res.data[item];
      }
    }
    res.data = dataObj;
    res.statusCodes = statusCodes;
    res.statusTexts = statusTexts;
    console.log('Response (SB): ', res);
  },
  async fetchFile(recipe, headers, method) {
    return new Promise((resolve, reject) => {
      let res;
      fetch(`${recipe.endPoint}${method.method}`, {
        method: recipe.type,
        body: headers,
      })
        .then((response) => {
          if (response.status === 500) {
            reject(`Failed to fetch method=${method}`);
          } else if (response.status === 401) {
            reject(response.statusText);
          } else if (response.status === 402) {
            reject(response.statusText);
          } else if (response.status === 409) {
            reject(response.statusText);
          } else if (response.status === 400) {
            reject(response.statusText);
          } else {
            res = response;
            return response.arrayBuffer();
          }
        })
        .then((parseData) => {
          downloadFile(parseData, method.fileName);
          resolve({
            data: true,
            statusCode: res.status,
            statusText: res.statusText,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  async initSession({user_uuid, token, refreshToken, language_id}) {
    const devID = await U.uuidvMain();
    const tokenExpirationTime = new Date();
    tokenExpirationTime.setTime(tokenExpirationTime.getTime() + 45 * 60 * 1000); // 45 minutes in milliseconds

    user_uuid && (await this.createCookie('ewttgpvwwkf', user_uuid));
    refreshToken &&
      (await this.createCookie('fdenfoindk', refreshToken, {
        // expires: tokenExpirationTime,
        // secure: true,
        // httpOnly: true,
      }));
    token &&
      (await this.createCookie('vvjsdugfie', token, {
        expires: tokenExpirationTime,
        // secure: true,
        // httpOnly: true,
      }));
    language_id && (await this.createCookie('mbohvbhfje', language_id));
    await this.createCookie('fgxkegwwkf', devID);
  },
  async getCookie(cKey) {
    let name = cKey + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let cookie of ca) {
      let c = cookie.trim();

      if (c.indexOf(name) == 0) {
        let cookieValue = c.substring(name.length);
        let expiresIndex = cookie.indexOf('expires=');

        if (expiresIndex !== -1) {
          let expiresString = c.substring(expiresIndex + 'expires='.length).trim();
          let expiresDate = new Date(expiresString);

          if (expiresDate.getTime() < Date.now()) {
            return {value: cookieValue, expired: true};
          }
        }
        return {value: cookieValue, expired: false};
      }
    }

    return {value: null, expired: false};
  },
  async createCookie(cKey, cValue, options = {}) {
    return new Promise((resolve) => {
      const defaultOptions = {secure: false, httpOnly: false, expires: null, sameSite: 'Lax'};

      const mergedOptions = {...defaultOptions, ...options};

      let cookieString = `${cKey}=${cValue}`;

      if (mergedOptions.expires) {
        const expires = new Date(mergedOptions.expires);
        cookieString += `;expires=${expires.toUTCString()}`;
      }

      if (mergedOptions.secure) {
        cookieString += ';secure';
      }

      if (mergedOptions.httpOnly) {
        cookieString += ';httpOnly';
      }

      if (mergedOptions.sameSite) {
        cookieString += `;sameSite=${mergedOptions.sameSite}`;
      }

      document.cookie = cookieString;

      resolve({success: true, message: 'Cookie created successfully'});
    });
  },
  async mGET(method, vars) {
    const newReq = new Cls.Plate(method, vars, 'GET');
    return newReq;
  },
  async mPOST(method, vars) {
    const newReq = new Cls.Plate(method, vars, 'POST');
    return newReq;
  },
  logout() {
    history.replaceState(null, null, window.location.pathname);
    location.reload();
  },
  async changeTab(newTab, vars) {
    if (thsSubTab && thsSubTab.length) {
      thsSubTab.savePage();
    }
    const tabList = document.querySelectorAll('.tablinks');
    const recipe = await this.getRecipe();
    recipe.page = newTab;
    recipe.div = 'tabContentContainer';
    recipe.pageType = 'tab';

    tabList.forEach((tab) => {
      tab.classList.contains('active') && tab.classList.remove('active');

      if (tab.id == newTab) {
        tab.classList.add('active');
      }
    });
    this.PREP(recipe, vars || {});
    recipe.page !== 'ADs_PersonalDetails' && U.hide('subTabs'); //@steph
  },
  async changeSubTab(newTab, vars) {
    const tabList = document.querySelectorAll('.subTablinks');
    const recipe = await this.getRecipe();
    recipe.page = newTab;
    recipe.div = 'tabContentContainer';
    recipe.pageType = 'subTab';

    tabList.forEach((tab) => {
      tab.classList.contains('active') && tab.classList.remove('active');

      if (tab.id == newTab) {
        tab.classList.add('active');
      }
    });
    this.PREP(recipe, vars || {});
  },
  async getTabs(htmlName) {
    const vars = {};
    vars.html_text = htmlName;
    const methods = [await O.mGET('navigation/get-tabs', vars)];
    const tabs = await O.PREP(methods);
    return tabs['navigation/get-tabs'].data;
  },
  async refreshTokens() {
    let res = false;

    try {
      const {value} = await this.getCookie('fdenfoindk');

      if (value) {
        const refresh_token = value;

        const response = await fetch(`${config.apiURL}login/refresh-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({refresh_token}),
        });

        if (response.ok) {
          const data = await response.json();
          const {token} = data.data;
          this.initSession({token: token});
          res = true;
        }
      }
    } catch (err) {
      console.error(err);
      res = false;
    }

    return res;
  },
};
