import U from '../js/utils';

export default async (elID, partyName, requiredYN) => {
  const opts = await U.buildOptsDD(communal.relationships, 'relationship_uuid', 'short_text');

  let checkboxes = '';

  const div = `
    <div id="${elID}Div" class="input-group">
    <label for="${elID}">Relationship to ${partyName}</label>
      <select id="${elID}" name="${elID}" val="select" 
      ${requiredYN ? 'required' : ''} 
      >
      ${opts}
      </select>
      ${checkboxes}
     <span fb-for="${elID}"></span>
    </div>
  `;

  const element = U.createHTMLElementFromString(div);
  return element;
};
