import comp from '../components/barrel';
import svg from '../js/svg';
import config from '../js/config';
import O from '../js/core';

export default {
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    this.recipe.methods.push(await O.mPOST('communal/languages'));

    const vars = {};
    vars.application_uuid = this.application_uuid;

    this.recipe.methods.push(await O.mPOST('wills/get-app-will-details', vars));
    this.recipe.methods.push(await O.mPOST('application/get-application-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const dataAppDets = data['wills/get-app-will-details'].data;
    const {pages} = data['application/get-application-detail'].data;

    // Build Progress Steps
    const progressDiv = U.id('progressDiv');
    progressDiv.innerHTML = '';

    let progressSteps = await comp.progressSteps(4);
    progressDiv.prepend(progressSteps);
    // filter out data without active_yn = false

    for (let page in pages) {
      if (pages[page].complete !== true) {
        await this.goToStep0();
        return;
      }
    }

    const filteredItems = dataAppDets.filter((item) => item.active_yn !== false);
    if (filteredItems.length) {
      // Go immediately to step number
      switch (filteredItems[0].current_step) {
        case 2:
          this.goToStep2();
          break;
        case 3:
          this.goToStep3();
          break;
        case 4:
          this.goToStep4();
          break;
        default:
          this.goToStep1(data);
      }
    } else {
      this.goToStep1(data);
    }
  },
  async goToStep0() {
    U.updateProgressSteps(0);
    this.changeSection('step0');
  },
  async goToStep1(data) {
    const dataLangDD = data['communal/languages'].data;

    // Language DropDown
    const langDD = await comp.select('language_id', dataLangDD, 'language_uuid', 'long_text', 'Will language', 1);
    U.id('langSelect').innerHTML = '';
    U.id('langSelect').appendChild(langDD);
    this.changeSection('step1');
  },
  async goToStep2() {
    this.changeSection('step2');
    U.updateProgressSteps(2);
    await this.step1Submit2();
  },
  async goToStep3() {
    this.changeSection('step3');
    U.updateProgressSteps(3);
    const draftList = await this.getDraftWills();
    const signedWill = await this.getSignedWill();
    this.buildDraftWillTable(draftList, 'draftWillTableDiv2');
    this.buildSignedWillTable(signedWill, 'signedWillTableDiv');
  },
  async goToStep4() {
    this.changeSection('step4');
    U.updateProgressSteps(4);
    const draftList = await this.getDraftWills();
    const signedWill = await this.getSignedWill();
    this.buildDraftWillTable(draftList, 'draftWillTableDiv3');
    this.buildSignedWillTable(signedWill, 'signedWillTableDiv2');
  },
  async updateStatus() {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    await O.PREP([await O.mPOST('application/get-app-status', vars)])
      .then(async (res) => {
        const {short_text} = res['application/get-app-status'].data;

        const status_row = U.id('status_row');
        status_row.innerHTML = '';
        let statusText = await comp.statusTag(short_text);
        status_row.appendChild(statusText);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async changeSection(activeSection) {
    this.updateStatus();
    const sections = document.querySelectorAll('.stepSection');
    sections.forEach((sect) => {
      sect.id && U.hide(sect.id);
    });
    U.unhide(activeSection);
  },
  async submitForDrafting() {
    let isValid = await U.validateForm('step1');
    if (!isValid) {
      return;
    }

    const msg = 'All information submitted has been checked and ... Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius vulputate pellentesque. Integer pharetra vel ante ac lobortis. Phasellus et elit id diam efficitur pellentesque. Nam sagittis, tortor non porta bibendum';
    const heading = 'SUBMIT FOR DRAFTING';
    const onclick = 'thsTab.step1Submit()';

    await comp.modal(heading, msg, onclick);
  },
  async step1Submit() {
    const vars = {};
    vars.language_uuid = U.id('language_id').value;
    vars.application_uuid = this.application_uuid;
    vars.special_instructions = U.id('specialInstrucBox').value;

    O.PREP([await O.mPOST('wills/req-new-draft', vars)])
      .then(async () => {
        this.goToStep2();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async step1Submit2() {
    const draftList = await this.getDraftWills();
    this.buildDraftWillTable(draftList, 'draftWillTableDiv1');
  },
  async getDraftWills() {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    const data = await O.PREP([await O.mPOST('wills/get-app-draft-wills', vars)])
      .then(async (res) => {
        return res['wills/get-app-draft-wills'].data;
      })
      .catch((err) => {
        console.log(err);
      });

    return data;
  },
  async getSignedWill() {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    const data = await O.PREP([await O.mPOST('wills/get-app-signed-wills', vars)])
      .then(async (res) => {
        return res['wills/get-app-signed-wills'].data;
      })
      .catch((err) => {
        console.log(err);
      });

    return data;
  },
  async buildDraftWillTable(data, tableDiv) {
    const table = U.id(tableDiv);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead', 'class', 'w-full');
    const headRow = thead.insertRow();

    const h1 = headRow.insertCell(0);
    h1.innerHTML = 'Date';
    const h2 = headRow.insertCell(1);
    h2.innerHTML = 'Uploaded By';
    const h3 = headRow.insertCell(2);
    h3.innerHTML = 'File Name';
    const h4 = headRow.insertCell(3);
    h4.innerHTML = 'File Type';
    const h5 = headRow.insertCell(4);
    h5.innerHTML = 'Reference';
    const h6 = headRow.insertCell(5);
    h6.innerHTML = '';
    thead.appendChild(headRow);
    table.appendChild(thead);

    // Body
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.length) {
      data.forEach(async (row) => {
        const willRow = tbody.insertRow();
        const r1 = willRow.insertCell(0);
        r1.innerHTML = await U.formatDate(row.created_date);
        const r2 = willRow.insertCell(1);
        r2.innerHTML = `${row.f_name} ${row.s_name}`;
        const r3 = willRow.insertCell(2);
        r3.innerHTML = row.file_name;
        const r4 = willRow.insertCell(3);
        r4.innerHTML = row.file_type;
        const r5 = willRow.insertCell(4);
        r5.innerHTML = row.file_reference;
        const r6 = willRow.insertCell(5);
        const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
        const linkSVG = svg.download(download_onclick);
        r6.appendChild(linkSVG);
        // r6.appendChild(await U.createHTMLElementFromString('<h4>Test</h4>'));
      });
    }

    if (tableDiv === 'draftWillTableDiv1') {
      const uploadRow = U.id('uploadRowDraft');
      uploadRow.innerHTML = '';

      const uploadDiv = U.divAtt('div');
      uploadDiv.classList.add('relative', 'w-full', 'flex', 'justify-center', 'items-center', 'transition-colors', 'duration-200', 'py-3', 'px-3', 'cursor-pointer', 'bg-primary-opacity', 'hover:bg-secondary', 'border-none');

      uploadDiv.setAttribute('role', 'button');
      uploadDiv.setAttribute('aria-label', 'Upload file');

      uploadDiv.onclick = () => thsTab.uploadFileModal();

      const textcontainer = U.divAtt('span');
      textcontainer.classList.add('ml-2');
      textcontainer.innerHTML = 'Upload Draft';

      const linkSVG = svg.uploadCloud();
      uploadDiv.appendChild(linkSVG);
      uploadDiv.appendChild(textcontainer);

      uploadDiv.style.margin = '0';
      uploadDiv.style.width = '100%';
      uploadDiv.style.boxSizing = 'border-box';

      uploadRow.appendChild(uploadDiv);
    }
  },
  async downloadFile(file_uuid) {
    const vars = {};
    vars.file_uuid = file_uuid;

    O.PREP([await O.mPOST('files/get-file-url', vars)])
      .then(async (res) => {
        window.open(res['files/get-file-url'].data.signedUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async uploadFileModal() {
    const popup = await this.fileUploadModal();
    document.body.prepend(popup);
  },
  async uploadSignedWillModal() {
    const draftWills = await this.getDraftWills();
    const pdfDraftWills = await draftWills.filter((item) => item.file_type === 'application/pdf');
    const popup = await this.signedWillUploadModal(pdfDraftWills);
    document.body.prepend(popup);
  },
  async uploadFile() {
    U.id('modal-btn').disabled = true;
    U.id('pdfUpload').classList.remove('input-error');
    U.id('wordUpload').classList.remove('input-error');
    U.id('referenceDiv').classList.remove('input-error');

    // Collect the input values
    const pdfFile = U.id('pdfUpload').files[0];
    const wordFile = U.id('wordUpload').files[0];
    const referenceVal = U.id('referenceDiv').value;
    const application_uuid = this.application_uuid;

    let isValid = true;

    console.log('pdfFile: ', pdfFile);
    console.log('pdfUpload: ', U.id('pdfUpload'));

    if (!pdfFile) {
      U.id('pdfUpload').classList.add('input-error');
      isValid = false;
    }

    if (pdfFile && pdfFile.type !== 'application/pdf') {
      U.id('pdfUpload').classList.add('input-error');
      isValid = false;
    }

    if (!wordFile) {
      U.id('wordUpload').classList.add('input-error');
      isValid = false;
    }

    if (wordFile && wordFile.type !== 'application/msword' && wordFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      U.id('wordUpload').classList.add('input-error');
      isValid = false;
    }

    if (!referenceVal) {
      U.id('referenceDiv').classList.add('input-error');
      isValid = false;
    }

    if (!isValid) {
      U.id('modal-btn').disabled = false;
      return;
    }
    // Create a FormData object to send the files and other fields
    const formData = new FormData();
    formData.append('pdfUpload', pdfFile);
    formData.append('wordUpload', wordFile);
    formData.append('file_reference', referenceVal);
    formData.append('application_uuid', application_uuid);

    const hdrs = await O.getAuthHdrs();

    // Send the form data using fetch
    try {
      const response = await fetch(`${config.apiURL}wills/upl-draft-will-files`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const result = await response.json();
      U.closeModal('fileUploadModal');
      this.step2Submit();
    } catch (error) {
      U.id('modal-btn').disabled = false;
      console.error('Error uploading files:', error);
      O.handleFetchError(error);
    }
  },
  async fileUploadModal() {
    const pdfInputDiv = await comp.inputFile({elID: 'pdfUpload', labelName: 'PDF Document:', accept: 'application/pdf', requiredYN: true});
    const wordInputDiv = await comp.inputFile({elID: 'wordUpload', labelName: 'Word Document:', accept: '.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document', requiredYN: true});
    const referenceDiv = await comp.inputText('referenceDiv', '', 'Reference:', true);
    const div = `
        <div id="fileUploadModal" class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <!-- Modal Content -->
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="text-center">
                  <!-- Heading -->
                  <h2 class="text-2xl font-semibold mb-4">Upload Draft</h2>
                  <!-- Subheading -->
                  <p class="text-gray-500 mb-6">Please upload both PDF and Word document.</p>
                </div>

                <!-- File Inputs -->
                <div class="space-y-4">
                  <!-- PDF Input -->
                  ${pdfInputDiv.outerHTML}

                  <!-- Word Document Input -->
                  ${wordInputDiv.outerHTML}

                  <!-- Reference Input -->
                  ${referenceDiv.outerHTML}

                </div>
              </div>

              <!-- Footer Buttons -->
              <div class="bg-gray-50 px-4 py-3 flex md:justify-end justify-center sm:px-6">
                <button class="btn-outline mr-3" onclick="U.closeModal('fileUploadModal')">Cancel</button>
                <button id="modal-btn" class="btn-primary" onclick="thsTab.uploadFile()">Upload Draft</button>
              </div>
            </div>
          </div>
        </div>
     `;

    return await U.createHTMLElementFromString(div);
  },
  async signedWillUploadModal(ddData) {
    const [fileInputDiv, recDate, signDate, filingNumber, textArea, select] = await Promise.all([
      comp.inputFile({elID: 'pdfUpload', labelName: 'PDF Document:', accept: 'application/pdf', requiredYN: true}),
      comp.inputDate('dateReceived', null, 'Date Will Received:', true),
      comp.inputDate('dateSigned', null, 'Date Will Signed:', true),
      comp.inputText('filingNumber', '', 'Filing Number:', true),
      comp.textarea('signingNotes', '', 'Further Notes:', true),
      comp.select_v2({
        elID: 'docVersion',
        ddData,
        valueDataName: 'file_uuid',
        textDataName: 'file_reference',
        labelName: 'Document Version Number:',
        requiredYN: true,
      }),
    ]);

    const div2 = `
      <div id="fileUploadModal" class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <!-- Modal Content -->
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="text-center">
                <!-- Heading -->
                <h2 class="text-2xl font-semibold mb-4">Upload Signed Will</h2>
              </div>
  
              <!-- Form Inputs -->
              <div class="space-y-4">
                <!-- Filing Number Input -->
                ${filingNumber.outerHTML}

                <!-- Date Will Received Input -->
                ${recDate.outerHTML}

                <!-- Date Will Signed Input -->
                ${signDate.outerHTML}

                <!-- Document Version Number Select -->
                ${select.outerHTML}

                <!-- PDF Document Input -->
                ${fileInputDiv.outerHTML}

                <!-- Notes Input -->
                ${textArea.outerHTML}
  
                <!-- Confirmation Checkbox -->
                <div class="text-left w-full mx-auto mt-4">
                  <label class="flex items-center w-full gap-2">
                    <input type="checkbox" class="form-checkbox text-primary w-1/6" id="confirmationCheckbox" required>
                    <span class="text-gray-700 w-5/6">I confirm the will has been signed correctly.</span>
                  </label>
                </div>

              </div>
            </div>
  
            <!-- Footer Buttons -->
            <div class="bg-gray-50 px-4 py-3 flex md:justify-end justify-center sm:px-6">
              <button class="btn-outline mr-3" onclick="U.closeModal('fileUploadModal')">Cancel</button>
              <button id="final-modal-btn" class="btn-primary" onclick="thsTab.finaliseWill()">Finalise Will</button>
            </div>
          </div>
        </div>
      </div>
    `;

    return await U.createHTMLElementFromString(div2);
  },
  async step2Submit() {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.current_step = 3;

    O.PREP([await O.mPOST('wills/upd-app-will-details', vars)])
      .then(async () => {
        this.goToStep3();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async buildSignedWillTable(data, tableDiv) {
    const table = U.id(tableDiv);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead', 'class', 'w-full');
    const headRow = thead.insertRow();

    const h1 = headRow.insertCell(0);
    h1.innerHTML = 'Date';
    const h2 = headRow.insertCell(1);
    h2.innerHTML = 'Uploaded By';
    const h3 = headRow.insertCell(2);
    h3.innerHTML = 'Date Will Recieved';
    const h4 = headRow.insertCell(3);
    h4.innerHTML = 'Date Will Signed';
    const h5 = headRow.insertCell(4);
    h5.innerHTML = 'Filing Number';
    const h6 = headRow.insertCell(5);
    h6.innerHTML = 'Document Version';
    const h7 = headRow.insertCell(6);
    h7.innerHTML = '';
    thead.appendChild(headRow);
    table.appendChild(thead);

    // Body
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.length) {
      data.forEach(async (row) => {
        const willRow = tbody.insertRow();
        const r1 = willRow.insertCell(0);
        r1.innerHTML = await U.formatDate(row.created_date);
        const r2 = willRow.insertCell(1);
        r2.innerHTML = `${row.f_name} ${row.s_name}`;
        const r3 = willRow.insertCell(2);
        r3.innerHTML = await U.formatDate(row.received_date);
        const r4 = willRow.insertCell(3);
        r4.innerHTML = await U.formatDate(row.signed_date);
        const r5 = willRow.insertCell(4);
        r5.innerHTML = row.filing_number;
        const r6 = willRow.insertCell(5);
        r6.innerHTML = row.file_reference;
        const r7 = willRow.insertCell(6);
        const download_onclick = `thsTab.downloadFile('${row.file_uuid}')`;
        const linkSVG = svg.download(download_onclick);
        r7.appendChild(linkSVG);

        const progressSteps = document.querySelectorAll('.progressActive');

        progressSteps.forEach((step) => {
          step.classList.add('progressSuccess'); // Add 'progressSuccess' class
          step.classList.remove('progressActive'); // Remove 'progressActive' class if it exists
        });
        thead.classList.add('bg-success');
      });
    }
    if (tableDiv === 'signedWillTableDiv') {
      const uploadRow = U.id('uploadRowSigned');
      uploadRow.innerHTML = '';

      const uploadDiv = U.divAtt('div');
      uploadDiv.classList.add('relative', 'w-full', 'flex', 'justify-center', 'items-center', 'transition-colors', 'duration-200', 'py-3', 'px-3', 'cursor-pointer', 'bg-primary-opacity', 'hover:bg-secondary', 'border-none');

      uploadDiv.setAttribute('role', 'button');
      uploadDiv.setAttribute('aria-label', 'Upload files');

      uploadDiv.onclick = () => thsTab.uploadSignedWillModal();

      const textcontainer = U.divAtt('span');
      textcontainer.classList.add('ml-2');
      textcontainer.innerHTML = 'Upload Signed Will';

      const linkSVG = svg.uploadCloud();
      uploadDiv.appendChild(linkSVG);
      uploadDiv.appendChild(textcontainer);

      uploadDiv.style.margin = '0';
      uploadDiv.style.width = '100%';
      uploadDiv.style.boxSizing = 'border-box';

      uploadRow.appendChild(uploadDiv);
    }
  },
  async finaliseWill() {

    U.id('final-modal-btn').disabled = true;

    U.id('pdfUpload').classList.remove('input-error');
    U.id('dateReceived').classList.remove('input-error');
    U.id('dateSigned').classList.remove('input-error');
    U.id('filingNumber').classList.remove('input-error');
    U.id('docVersion').classList.remove('input-error');
    U.id('confirmationCheckbox').classList.remove('input-error');

    // Collect the input values
    const pdfFile = U.id('pdfUpload').files[0];
    const dateReceived = U.id('dateReceived').value;
    const dateSigned = U.id('dateSigned').value;
    const filingNumber = U.id('filingNumber').value;
    const docVersion = U.id('docVersion').value;
    const confirmationCheckbox = U.id('confirmationCheckbox').checked;
    const signingNotes = U.id('signingNotes').value;

    const application_uuid = this.application_uuid;

    let isValid = true;

    if (!pdfFile) {
      U.id('pdfUpload').classList.add('input-error');
      isValid = false;
    }

    if (pdfFile && pdfFile.type !== 'application/pdf') {
      U.id('pdfUpload').classList.add('input-error');
      isValid = false;
    }

    if (!dateReceived) {
      U.id('dateReceived').classList.add('input-error');
      isValid = false;
    }

    if (!dateSigned) {
      U.id('dateSigned').classList.add('input-error');
      isValid = false;
    }

    if (!filingNumber) {
      U.id('filingNumber').classList.add('input-error');
      isValid = false;
    }

    if (!docVersion) {
      U.id('docVersion').classList.add('input-error');
      isValid = false;
    }

    if (!confirmationCheckbox) {
      U.id('confirmationCheckbox').classList.add('input-error');
      isValid = false;
    }

    if (!isValid) {
      U.id('final-modal-btn').disabled = false;
      return;
    }

    // Create a FormData object to send the files and other fields
    const formData = new FormData();
    formData.append('uploadFile', pdfFile);
    formData.append('application_uuid', application_uuid);
    formData.append('received_date', dateReceived);
    formData.append('signed_date', dateSigned);
    formData.append('filing_number', filingNumber);
    formData.append('document_version_uuid', docVersion);
    formData.append('author_signature', confirmationCheckbox);
    formData.append('notes', signingNotes);

    const hdrs = await O.getAuthHdrs();

    // Send the form data using fetch
    try {
      const response = await fetch(`${config.apiURL}wills/upl-signed-will`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${hdrs.token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const result = await response.json();
      U.closeModal('fileUploadModal');
      this.goToStep4();
    } catch (error) {
      console.error('Error uploading files:', error);
      U.id('final-modal-btn').disabled = false;
      O.handleFetchError(error);
    }
  },
  async requestRedraft() {
    U.id('reasonNotes').classList.remove('input-error');

    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.redraft_reason = U.id('reasonNotes').value;

    if (!vars.redraft_reason) {
      U.id('reasonNotes').classList.add('input-error');
      return;
    }

    O.PREP([await O.mPOST('wills/req-will-redraft', vars)])
      .then(async () => {
        U.closeModal('redraftModal');
        this.render();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async requestRedraftModal() {
    const popup = await this.redraftModal();
    document.body.prepend(popup);
  },
  async redraftModal() {
    const div = `
		  <div id="redraftModal" class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black">
			<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
			  <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
				<!-- Modal Content -->
				<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
				  <div class="text-center">
					<!-- Heading -->
					<h2 class="text-2xl font-semibold mb-4">Request For Redraft</h2>
				  </div>
	  
				  <!-- Reason/Notes Text Area -->
				  <div class="text-left">
					<label for="reasonNotes" class="block text-gray-700 font-medium">Reason/Notes:</label>
					<textarea id="reasonNotes" placeholder="Reason/Notes" class="block w-4/5 mx-auto px-4 py-2 mt-2 bg-white border border-gray-300 rounded-lg resize-none focus:outline-none"></textarea>
				  </div>
				</div>
	  
				<!-- Footer Buttons -->
				<div class="bg-gray-50 px-4 py-3 flex md:justify-end justify-center sm:px-6">
				  <button class="btn-outline mr-3" onclick="U.closeModal('redraftModal')">Cancel</button>
				  <button id="modal-btn" class="btn-primary" onclick="thsTab.requestRedraft()">Request Redraft</button>
				</div>
			  </div>
			</div>
		  </div>
		`;

    return await U.createHTMLElementFromString(div);
  },
};
