import comp from '../components/barrel';
import svg from '../js/svg';

export default {
  application_uuid: null,
  testament: null,
  party1_name: null,
  party1_user_uuid: null,
  party1_soleheir_uuid: null,
  party1_soleheir_name: null,
  party2_name: null,
  party2_user_uuid: null,
  party2_soleheir_uuid: null,
  party2_soleheir_name: null,
  relationships: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-app-summary', vars));
    this.recipe.methods.push(await O.mPOST('application/get-heir_details', vars));
    this.recipe.methods.push(await O.mPOST('communal/relationships', vars));
    //this.recipe.methods.push(await O.mPOST('communal/relationships', vars));
    // this.recipe.methods.push(await O.mPOST('application/get-sole-heir', vars));
    // this.recipe.methods.push(await O.mPOST('application/get-heir-list', vars));
    // this.recipe.methods.push(await O.mPOST('application/get-obliteration-list', vars));

    // Clear table to prevent duplication when sum ratios (Better solution needed)
    if (await U.id('heir_list')) {
      U.id('heir_list').innerHTML = '';
    }
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    Object.keys(communal).length === 0 && (await U.getCommunalDropdowns());
    this.relationships = data[`communal/relationships`].data;
    const summary = data['application/get-app-summary'].data;
    this.testament = summary[0].testament;
    const heirs = data['application/get-heir_details'].data;

    // const heirs = data['application/get-sole-heir'].data;
    // const heirList = data['application/get-heir-list'].data;
    // const famOblitList = data['application/get-obliteration-list'].data;

    this.party1_name = heirs.party1.name;
    this.party1_user_uuid = heirs.party1.user_uuid;
    this.party1_soleheir_name = heirs.party1.heir_name;
    this.party1_soleheir_uuid = heirs.party1.heir_uuid;
    // this.heir_notes = heirs.heir_notes;
    if (heirs.heir_notes.length) {
      await this.setHeirNotes(heirs);
    }

    //@steph here is the heir_notes // [{"table_index","application_uuid","note"}]

    // -------------------------------
    //Sole heir section
    if (this.testament === 'Joint') {
      this.party2_user_uuid = heirs.party2.user_uuid;
      this.party2_name = heirs.party2.name;
      this.party2_soleheir_name = heirs.party2.heir_name;
      this.party2_soleheir_uuid = heirs.party2.heir_uuid;

      await this.buildSoleHeirs(heirs);
      const text = await comp.textarea('heirNotes_1', this.heir_notes_1 || '', 'Notes for Sole Heir', 0);
      U.id('heirNotes_1_Div').appendChild(text);
    } else {
      U.hide('soleHeirDiv');
    }

    // -------------------------------
    // Allocate heirs section

    await this.buildHeirListTable(heirs.heir_list);
    this.checkRatio('noUpdt');
    this.testament === 'Joint' ? (U.id('hB').innerHTML = 'B. Will of the survivor and/or in the event of simultaneous death') : (U.id('hB').innerHTML = 'A. Heir Ratio');

    const text2 = await comp.textarea('heirNotes_2', this.heir_notes_2 || '', 'Notes for Will of the survivor and/or in the event of simultaneous death', 0);
    U.id('heirNotes_2_Div').appendChild(text2);

    // -------------------------------
    // Family Obliteration section
    await this.buildFamOblitHeirListTable(heirs.obliteration_list);
    this.checkRatio('noUpdt');
    this.testament === 'Joint' ? (U.id('hC').innerHTML = 'C. Family Obliteration') : (U.id('hC').innerHTML = 'B. Family Obliteration');

    const text3 = await comp.textarea('heirNotes_3', this.heir_notes_3 || '', 'Notes for Family Obliteration', 0);
    U.id('heirNotes_3_Div').appendChild(text3);
  },
  async setHeirNotes(heirs) {
    new Promise((resolve) => {
      heirs.heir_notes.forEach((notes) => {
        this[`heir_notes_${notes.table_index}`] = notes.note;
      });
      resolve();
    });
  },
  async buildSoleHeirs(heirs) {
    const soleHeirDiv = U.id('soleHeirDiv');
    // -------------------------------
    // Party 1
    const p1Div = U.id('party1Div');
    const p1 = U.divAtt('h6', 'class', '');
    p1.innerHTML = `Party 1 - ${this.party1_name} sole heir`;
    const label = `Party 2 (${this.party2_name}) sole heir if party 1 first dying`;
    let p1sole = true;

    if (!heirs.party1.heir_uuid) {
      p1sole = false;
    }
    //console.log(p1sole);
    const p1CheckBox = await comp.inputCheckbox('party1SoleHeirCheck', p1sole, label);
    p1Div.appendChild(p1);
    p1Div.appendChild(p1CheckBox);
    U.id('party1SoleHeirCheck').setAttribute('onchange', `thsSubTab.checkSoleHeirs('party1')`);

    // Other sole heir btn ---
    const addHeirBtn = U.divAtt('button', 'class', 'btn-icon btn-transparent mt-2');
    addHeirBtn.setAttribute('onclick', `thsSubTab.addSoleHeirs('party1')`);
    addHeirBtn.id = `btnparty1SoleHeir`;
    p1Div.appendChild(addHeirBtn);

    // Add name tag and button if party 2 not sole heir
    if (!p1sole && heirs.party1.heir_uuid) {
      this.buildNameTag('party1');
    }
    await this.buildParty1List(heirs.party1_list, p1sole);
    this.checkParty1Ratio('noUpdt');
    // -------------------------------
    // Party 2
    const p2Div = U.id('party2Div');
    const p2 = U.divAtt('h6', 'class', '');
    p2.innerHTML = `Party 2 - ${this.party2_name} sole heir`;
    const label2 = `Party 1 (${this.party1_name}) sole heir if party 2 first dying`;
    let p2sole = true;

    if (!heirs.party2.heir_uuid) {
      p2sole = false;
    }
    const p2CheckBox = await comp.inputCheckbox('party2SoleHeirCheck', p2sole, label2);
    p2Div.appendChild(p2);
    p2Div.appendChild(p2CheckBox);
    U.id('party2SoleHeirCheck').setAttribute('onchange', `thsSubTab.checkSoleHeirs('party2')`);

    // Other sole heir btn ---
    const addHeirBtn2 = U.divAtt('button', 'class', 'btn-icon btn-transparent mt-2');
    addHeirBtn2.setAttribute('onclick', `thsSubTab.addSoleHeirs('party2')`);
    addHeirBtn.id = `btnparty2SoleHeir`;
    p2Div.appendChild(addHeirBtn2);

    // Add name tag annd button if party 2 not sole heir
    if (!p2sole && heirs.party2.heir_uuid) {
      this.buildNameTag('party2');
    }
    await this.buildParty2List(heirs.party2_list, p2sole);
    this.checkParty2Ratio('noUpdt');

    // -------------------------------
  },
  async buildParty1List(list, active) {
    //console.log(list);
    const table = U.id(`party1_heir_list`);
    table.innerHTML = '';
    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';
    thead.appendChild(headRow);
    table.appendChild(thead);
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (list.children.length) {
      const childHrow = tbody.insertRow();
      const childrow = childHrow.insertCell(0);
      childrow.classList.add('subTHead');
      childrow.innerHTML = 'Children';
      childrow.colSpan = 4;

      list.children.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = (await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P1HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty1Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        childRow.insertCell(3);
      });
    }
    if (list.heirs.length) {
      const otherHrow = tbody.insertRow();
      const otherRow = otherHrow.insertCell(0);
      otherRow.classList.add('subTHead');
      otherRow.innerHTML = 'Heirs';
      otherRow.colSpan = 4;

      list.heirs.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = row.rel_party1; //(await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P1HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty1Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }
    if (list.company.length) {
      list.company.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P1HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty1Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }
    if (!list.heirs.length && !list.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 4;
    }
    if (active) {
      U.hide('party1Heirs');
    } else {
      U.unhide('party1Heirs');
    }
    const totalRow = tbody.insertRow();
    totalRow.classList.add('P1heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'P1totalRatio';
    totalRow.insertCell(3);
    //this.checkParty2Ratio('noUpdt');
  },
  async buildParty2List(list, active) {
    const table = U.id(`party2_heir_list`);
    table.innerHTML = '';
    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const removeH = headRow.insertCell(3);
    removeH.innerHTML = 'Remove';
    thead.appendChild(headRow);
    table.appendChild(thead);
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (list.children.length) {
      const childHrow = tbody.insertRow();
      const childrow = childHrow.insertCell(0);
      childrow.classList.add('subTHead');
      childrow.innerHTML = 'Children';
      childrow.colSpan = 4;

      list.children.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = (await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P2HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty1Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        childRow.insertCell(3);
      });
    }
    if (list.heirs.length) {
      const otherHrow = tbody.insertRow();
      const otherRow = otherHrow.insertCell(0);
      otherRow.classList.add('subTHead');
      otherRow.innerHTML = 'Heirs';
      otherRow.colSpan = 4;

      list.heirs.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = row.rel_party2; //(await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P2HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty2Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }
    if (list.company.length) {
      list.company.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'P2HeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkParty2Ratio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        const removeIcon = childRow.insertCell(3);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }
    if (!list.heirs.length && !list.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 4;
    }
    if (active) {
      U.hide('party2Heirs');
    } else {
      U.unhide('party2Heirs');
    }
    const totalRow = tbody.insertRow();
    totalRow.classList.add('P2heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'P2totalRatio';
    totalRow.insertCell(3);
    //this.checkParty2Ratio('noUpdt');
  },
  checkParty1Ratio(noUpdt) {
    //@mike
    let sum = 0;
    const ratios = document.querySelectorAll('.P1HeirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }
      sum += Number(input.value) || 0;
    });
    if (sum > 100) {
      sum = 100;
    } else if (sum > 99.8 && sum < 100) {
      sum = 100;
    }
    U.id('P1totalRatio').innerHTML = `${sum}/100`;
    if (sum === 100) {
      U.id('P1totalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateP1HeirRatios();
      }
    } else {
      U.id('P1totalRatio').classList.add('error');
    }
  },
  async updateP1HeirRatios() {
    const ratios = document.querySelectorAll('.P1HeirRatio');
    const vars = {party1_heir: true, party2_heir: false};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      dataObj.share_ratio = radio.value;
      vars.heirs.push(dataObj);
    });
    O.PREP([await O.mPOST('application/ups-sole-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-sole-heir-list'].data;
        const msg = res['application/ups-sole-heir-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },
  async addP1Heir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitP1Heir(data);
    }
  },
  async submitP1Heir(data) {
    const vars = {party1_heir: true, party2_heir: false};
    vars.application_uuid = this.application_uuid;

    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-sole-heir-list', vars)]).then(async (res) => {
      const msg = res['application/ins-sole-heir-list'].status;
      comp.toast(msg);

      const newHeirData = res['application/ins-sole-heir-list'].data.party1_list;
      const checked = U.id('party1SoleHeirCheck').checked;
      this.buildParty1List(newHeirData, checked);
      this.checkParty1Ratio('noUpdt');
    });
  },
  checkParty2Ratio(noUpdt) {
    //@mike
    let sum = 0;
    const ratios = document.querySelectorAll('.P2HeirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }
      sum += Number(input.value) || 0;
    });
    if (sum > 100) {
      sum = 100;
    } else if (sum > 99.8 && sum < 100) {
      sum = 100;
    }
    U.id('P2totalRatio').innerHTML = `${sum}/100`;
    if (sum === 100) {
      U.id('P2totalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateP2HeirRatios();
      }
    } else {
      U.id('P2totalRatio').classList.add('error');
    }
  },
  async updateP2HeirRatios() {
    console.log('updateP2HeirRatios');
    const ratios = document.querySelectorAll('.P2HeirRatio');
    const vars = {party1_heir: false, party2_heir: true};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      dataObj.share_ratio = radio.value;
      vars.heirs.push(dataObj);
    });
    O.PREP([await O.mPOST('application/ups-sole-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-sole-heir-list'].data;
        const msg = res['application/ups-sole-heir-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },
  async addP2Heir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitP2Heir(data);
    }
  },
  async submitP2Heir(data) {
    const vars = {party2_heir: true, party1_heir: false};
    vars.application_uuid = this.application_uuid;

    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-sole-heir-list', vars)]).then(async (res) => {
      const msg = res['application/ins-sole-heir-list'].status;
      comp.toast(msg);

      const newHeirData = res['application/ins-sole-heir-list'].data.party2_list;
      const checked = U.id('party2SoleHeirCheck').checked;
      this.buildParty2List(newHeirData, checked);
      this.checkParty2Ratio('noUpdt');
    });
  },
  async buildNameTag(forParty) {
    const nameTagSoleHeir = U.divAtt('div', 'class', 'nameTag mt-4');
    nameTagSoleHeir.id = `nameTag${forParty}SoleHeir`;
    nameTagSoleHeir.innerHTML = forParty === 'party1' ? this.party1_soleheir_name : this.party2_soleheir_name;
    U.id(`${forParty}Div`).appendChild(nameTagSoleHeir);
  },
  checkSoleHeirs(forParty) {
    const pCheckBoxID = forParty === 'party1' ? 'party1SoleHeirCheck' : 'party2SoleHeirCheck';
    const cb = U.id(`${pCheckBoxID}`);
    if (cb.checked === true) {
      // remove old heir
      if (U.id(`nameTag${forParty}SoleHeir`)) {
        U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
      }
      if (forParty === 'party1') {
        U.hide('party1Heirs');
      } else {
        U.hide('party2Heirs');
      }
    } else {
      if (forParty === 'party1') {
        U.unhide('party1Heirs');
      } else {
        U.unhide('party2Heirs');
      }
    }
    this.updateSoleHeirs();
  },
  // addSoleHeirs(forParty) {
  //   // const pCheckBoxID = forParty === 'party1' ? 'party1SoleHeirCheck' : 'party2SoleHeirCheck';
  //   // const cb = U.id(`${pCheckBoxID}`);

  //   // if (cb.checked === true) {
  //   //   this.updateSoleHeirs();
  //   //   // remove old heir
  //   //   if (U.id(`nameTag${forParty}SoleHeir`)) {
  //   //     U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
  //   //   };
  //   // } else {
  //   //   this.checkUser(forParty);
  //   // };

  //   this.checkUser(forParty);
  // },
  async addSoleHeirs(forParty) {
    const inforObj = {
      relationship: [this.party1_name],
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);

    if (data && data !== 'Canceled') {
      // remove old heir
      if (U.id(`nameTag${forParty}SoleHeir`)) {
        U.id(`${forParty}Div`).removeChild(U.id(`nameTag${forParty}SoleHeir`));
      }
      //set new soleHeir
      (await forParty) === 'party1' ? (this.party1_soleheir_uuid = data.user_uuid) : (this.party2_soleheir_uuid = data.user_uuid);
      (await forParty) === 'party1' ? (this.party1_soleheir_name = data.full_name) : (this.party2_soleheir_name = data.full_name);
      // submit new heir
      this.buildNameTag(forParty);
      this.updateSoleHeirs();
    } else {
      //cancelled adding new sole heir
      // U.id(`${forParty}SoleHeirCheck`).checked = true;
    }
  },
  async updateSoleHeirs() {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    vars.party1_heir_yn = U.id('party1SoleHeirCheck').checked;
    if (vars.party1_heir_yn) {
      vars.party1_heir_uuid = vars.party1_heir_yn ? this.party2_user_uuid : this.party1_soleheir_uuid;
    }

    vars.party2_heir_yn = U.id('party2SoleHeirCheck').checked;
    if (vars.party2_heir_yn) {
      vars.party2_heir_uuid = vars.party2_heir_yn ? this.party1_user_uuid : this.party2_soleheir_uuid;
    }

    O.PREP([await O.mPOST('application/ups_sole_heir', vars)]).then((res) => {
      const data = res['application/ups_sole_heir'].data;
      const msg = res['application/ups_sole_heir'].status;
      comp.toast(msg);
    });
  },

  //Heir List -------
  async buildHeirListTable(data) {
    const table = U.id(`heir_list`);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const editH = headRow.insertCell(3);
    editH.innerHTML = 'Edit';
    const removeH = headRow.insertCell(4);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (data.children.length) {
      const childHrow = tbody.insertRow();
      const childrow = childHrow.insertCell(0);
      childrow.classList.add('subTHead');
      childrow.innerHTML = 'Children';
      childrow.colSpan = 5;

      data.children.forEach(async (row) => {
        //console.log(row)
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Child'; //(await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        // ratioInpt.setAttribute('type', 'number');
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);
        childRow.insertCell(3);
        childRow.insertCell(4);
      });
    }

    if (data.heirs.length) {
      const otherHrow = tbody.insertRow();
      const otherRow = otherHrow.insertCell(0);
      otherRow.classList.add('subTHead');
      otherRow.innerHTML = 'Heirs';
      otherRow.colSpan = 5;

      data.heirs.forEach(async (row) => {
        //console.log(row)
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = row.rel_party1 || row.rel_party2; //(await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);

        const editIcon = childRow.insertCell(3);
        const edit_onclick = `thsSubTab.fetchEditHeir('${row.application_heirs_uuid}','${row.heir_uuid}','heir')`;
        const editSVG = svg.edit(edit_onclick);
        editIcon.appendChild(editSVG);

        const removeIcon = childRow.insertCell(4);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (data.company.length) {
      // const otherHrow = tbody.insertRow();
      // const otherRow = otherHrow.insertCell(0);
      // otherRow.classList.add('subTHead');
      // otherRow.innerHTML = 'Heirs - Organisations';
      // otherRow.colSpan = 4;

      data.company.forEach(async (row) => {
        //console.log(row)
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'heirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);

        const editIcon = childRow.insertCell(3);
        const edit_onclick = `thsSubTab.fetchEditHeir('${row.bequeath_uuid}','${row.heir_uuid}','company')`;
        const editSVG = svg.edit(edit_onclick);
        editIcon.appendChild(editSVG);

        const removeIcon = childRow.insertCell(4);
        const onclick = `thsSubTab.removeHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (!data.heirs.length && !data.children.length && !data.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 5;
    }

    const totalRow = tbody.insertRow();
    totalRow.classList.add('heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'totalRatio';
    totalRow.insertCell(3);
    totalRow.insertCell(4);

    this.checkRatio('noUpdt');
  },
  checkRatio(noUpdt) {
    let sum = 0;
    const ratios = document.querySelectorAll('.heirRatio');
    // ('.FamOblitHeirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }

      let numericValue = input.value.replace(/[^0-9.]/g, '');
      sum += Number(numericValue) || 0;
    });

    // if (sum > 100) {
    //   sum = 100;
    // } else
    if (sum > 99.8 && sum < 100) {
      sum = 100;
    }

    U.id('totalRatio').innerHTML = `${sum}/100`;
    if (sum === 100) {
      U.id('totalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateHeirRatios();
      }
    } else {
      U.id('totalRatio').classList.add('error');
    }
  },
  async addHeir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitHeir(data);
    }
  },
  async fetchEditHeir(uuid, heir_uuid, type) {
    let vars = {bequeath_uuid: null, application_obliteration_uuid: null, application_heirs_uuid: null};
    if (type == 'company') {
      vars.bequeath_uuid = uuid;
    } else if (type == 'oblit') {
      vars.application_obliteration_uuid = uuid;
    } else {
      vars.application_heirs_uuid = uuid;
    }
    //'${row.application_heirs_uuid}','${row.heir_uuid}','heir'
    //'${row.bequeath_uuid}','${row.heir_uuid}','company'
    //'${row.application_obliteration_uuid}','${row.heir_uuid}','oblit'
    //'${row.bequeath_uuid}','${row.heir_uuid}','company'

    vars.application_uuid = this.application_uuid;
    vars.heir_uuid = heir_uuid;
    O.PREP([await O.mPOST('application/get-heir', vars)])
      .then(async (res) => {
        const heir = res['application/get-heir'].data;
        this.EditForm(heir, type);
      })
      .catch(console.error);
  },
  async EditForm(data, section) {
    let formHtml;
    let val1;
    let val2 = null;
    if (section == 'heir') {
      val1 = data.application_heirs_uuid;
      val2 = data.heir_uuid;
      formHtml = await this.buildEditHeir(data);
    } else if (section == 'oblit') {
      val1 = data.application_obliteration_uuid;
      val2 = data.heir_uuid;
      formHtml = await this.buildEditHeir(data);
    } else {
      val1 = data.bequeath_uuid;
      formHtml = await this.buildEditOrg(data);
    }
    const form = formHtml.outerHTML;
    const sectionCopy = this[`${section}`];

    const div = `
    <div
      id="childBox"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Edit Heir</h4>

      <div id="newChild">
      ${form}
      </div>

      <div
      class="flex justify-center items-center" >
      <button class="btn-outline mr-4" onclick="U.closeModal('childBox')">Cancel</button>
      <button id="addChild-btn" onclick="thsSubTab.submitEditHeir('${val1}','${val2}','${section}')">Update</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async buildEditHeir(child) {
    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
    const title = U.divAtt('h6', 'class', 'text-primary');
    title.innerHTML = `Heir Information`;
    let e_f_name = await comp.inputText(`f_name`, child.f_name, 'Full names ', 1);
    let e_s_name = await comp.inputText(`s_name`, child.s_name, 'Surname ', 1);
    let e_rel = await comp.select(`relationship`, this.relationships, 'relationship_uuid', 'short_text', 'Relationship', 1);
    const relationship_uuid = child.rel1_uuid || child.rel2_uuid;
    this.rel_party = child.rel1_uuid ? 'party1' : 'party2';
    const option = e_rel.querySelector(`option[value="${relationship_uuid}"]`);
    if (option) {
      // Set the selected attribute to make it the selected option
      option.setAttribute('selected', 'selected');
    }
    //let e_dob = await comp.inputDate(`newChild_dob`, child.dob, 'Date of birth ', 1);
    //let id_nr = await comp.inputNumber(`newChild_id`, child.id_nr, 'ID Number', 1);
    let p1_id_pass = await comp.inputIDPassport('idPass', child.id_nr ? 1 : 0, child.id_nr ? child.id_nr : child.passport_nr, 1);
    grid2.appendChild(e_f_name);
    grid2.appendChild(e_s_name);
    grid2.appendChild(e_rel);
    //grid2.appendChild(e_dob);
    grid2.appendChild(p1_id_pass);
    return grid2;
  },
  async buildEditOrg(child) {
    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
    const title = U.divAtt('h6', 'class', 'text-primary');
    title.innerHTML = `Company Information`;
    let name = await comp.inputText(`orgName`, child.f_name, 'Name ', 1);
    let contact = await comp.inputText(`orgContact`, child.contact, 'Contact ', 1);
    const notes = await comp.textarea('orgNotes', child.notes, 'Notes', 1);

    grid2.appendChild(name);
    grid2.appendChild(contact);
    grid2.appendChild(notes);
    return grid2;
  },
  async submitEditHeir(val1, val2, section) {
    // U.loaderBtn(1, 'addChild-btn');
    // let isValid = await U.validateForm('newChild');
    // if (!isValid) {
    //   U.loaderBtn(0, 'addChild-btn');
    //   return;
    // }
    const vars = {};
    vars.application_uuid = this.application_uuid;
    if (section == 'heir') {
      vars.application_heirs_uuid = val1;
      vars.heir_uuid = val2;
      vars.f_name = U.id('f_name').value;
      vars.s_name = U.id('s_name').value;
      if (this.rel_party == 'party1') {
        vars.rel_party1 = U.id('relationship').value;
        vars.rel_party2 = null;
      } else {
        vars.rel_party2 = U.id('relationship').value;
        vars.rel_party1 = null;
      }
      U.id('idPass').checked ? (vars.id_nr = U.id('idPass').value) : (vars.passport_nr = U.id('idPass').value);
    } else if (section == 'oblit') {
      vars.application_obliteration_uuid = val1;
      vars.heir_uuid = val2;
      vars.f_name = U.id('f_name').value;
      vars.s_name = U.id('s_name').value;
      if (this.rel_party == 'party1') {
        vars.rel_party1 = U.id('relationship').value;
        vars.rel_party2 = null;
      } else {
        vars.rel_party1 = null;
        vars.rel_party2 = U.id('relationship').value;
      }
      U.id('idPass').checked ? (vars.id_nr = U.id('idPass').value) : (vars.passport_nr = U.id('idPass').value);
    } else {
      vars.bequeath_uuid = val1;
      vars.orgName = U.id('orgName').value;
      vars.orgContact = U.id('orgContact').value;
      vars.orgNotes = U.id('orgNotes').value;
    }

    O.PREP([await O.mPOST('application/ups-heir', vars)])
      .then(async (res) => {
        const msg = res['application/ups-heir'].status;
        comp.toast(msg);
        U.closeModal('childBox');
        this.buildHeirListTable(res['application/ups-heir'].data.heirs);
        this.buildFamOblitHeirListTable(res['application/ups-heir'].data.obliterations);
        // const newChildData = res['application/ups-heir'].data[`${section}`];
        // this.buildChildTable(section, newChildData, section === 'other' ? 1 : 0);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'addChild-btn');
      });
  },
  async submitHeir(data) {
    const vars = {};
    vars.application_uuid = this.application_uuid;

    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-heir-list', vars)])
      .then(async (res) => {
        const msg = res['application/ins-heir-list'].status;
        comp.toast(msg);

        const newHeirData = res['application/ins-heir-list'].data;
        this.buildHeirListTable(newHeirData);
      })
      .catch(() => {
        console.error;
        U.loaderBtn(0, 'newHeir-btn');
      });
  },
  async removeHeir(heirID) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_heirs_uuid = heirID;

    O.PREP([await O.mPOST('application/rmv-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/rmv-heir-list'].data;
        const msg = res['application/rmv-heir-list'].status;
        comp.toast(msg);
        this.buildHeirListTable(newHeirData);
      })
      .catch(console.error);
  },
  async updateHeirRatios() {
    const ratios = document.querySelectorAll('.heirRatio');
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      let numericValue = radio.value.replace(/[^0-9.]/g, '');
      dataObj.share_ratio = numericValue;
      vars.heirs.push(dataObj);
    });
    //console.log(vars);
    O.PREP([await O.mPOST('application/ups-heir-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-heir-list'].data;
        const msg = res['application/ups-heir-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },
  //Heir List -------
  async buildFamOblitHeirListTable(data) {
    const table = U.id(`famOblit_list`);
    table.innerHTML = '';

    // HEADER
    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const relH = headRow.insertCell(1);
    relH.innerHTML = 'Relationship';
    const ratioH = headRow.insertCell(2);
    ratioH.innerHTML = 'Ratio';
    const editH = headRow.insertCell(3);
    editH.innerHTML = 'Edit';
    const removeH = headRow.insertCell(4);
    removeH.innerHTML = 'Remove';

    thead.appendChild(headRow);
    table.appendChild(thead);

    // BODY
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);

    if (data.heirs.length) {
      data.heirs.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = row.rel_party1 || row.rel_party2; //(await U.getTextByUuid(communal.relationships, 'relationship_uuid', row.relationship, 'short_text')) || '';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'FamOblitHeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkFamOblitRatio()');
        // ratioInpt.setAttribute('type', 'number');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);

        const editIcon = childRow.insertCell(3);
        const edit_onclick = `thsSubTab.fetchEditHeir('${row.application_obliteration_uuid}','${row.heir_uuid}','oblit')`;
        const editSVG = svg.edit(edit_onclick);
        editIcon.appendChild(editSVG);

        const removeIcon = childRow.insertCell(4);
        const onclick = `thsSubTab.removeFamOblitHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (data.company.length) {
      data.company.forEach(async (row) => {
        const childRow = tbody.insertRow();
        const name = childRow.insertCell(0);
        name.innerHTML = row.name;
        const rel = childRow.insertCell(1);
        rel.innerHTML = 'Organisation';
        const ratio = childRow.insertCell(2);
        const ratioInpt = U.divAtt('input', 'class', 'FamOblitHeirRatio');
        ratioInpt.setAttribute('onkeyup', 'thsSubTab.checkFamOblitRatio()');
        ratioInpt.setAttribute('value', !row.share_ratio ? 0 : `${row.share_ratio}`);
        ratioInpt.id = row.heir_uuid;
        ratio.appendChild(ratioInpt);

        const editIcon = childRow.insertCell(3);
        const edit_onclick = `thsSubTab.fetchEditHeir('${row.bequeath_uuid}','${row.heir_uuid}','company')`;
        const editSVG = svg.edit(edit_onclick);
        editIcon.appendChild(editSVG);

        const removeIcon = childRow.insertCell(4);
        const onclick = `thsSubTab.removeFamOblitHeir('${row.heir_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      });
    }

    if (!data.heirs.length && !data.company.length) {
      //No  Heirs ---
      const heirRow = tbody.insertRow();
      const noHeir = heirRow.insertCell(0);
      noHeir.innerHTML = 'No heirs added.';
      noHeir.colSpan = 5;
    }

    const totalRow = tbody.insertRow();
    totalRow.classList.add('heirTotal');
    const totalH = totalRow.insertCell(0);
    totalH.innerHTML = 'Total';
    totalRow.insertCell(1);
    const total = totalRow.insertCell(2);
    total.id = 'FamOblittotalRatio';
    totalRow.insertCell(3);
    totalRow.insertCell(4);

    this.checkFamOblitRatio('noUpdt');
  },
  checkFamOblitRatio(noUpdt) {
    //@mike
    let sum = 0;
    const ratios = document.querySelectorAll('.FamOblitHeirRatio');

    ratios.forEach((input) => {
      let value = parseFloat(input.value);
      if (isNaN(value) || value < 0) {
        input.value = '';
      }
      let numericValue = input.value.replace(/[^0-9.]/g, '');
      sum += Number(numericValue) || 0;
    });
    // if (sum > 100) {
    //   sum = 100;
    // } else
    if (sum > 99.8 && sum < 100) {
      sum = 100;
    }
    U.id('FamOblittotalRatio').innerHTML = `${sum}/100`;
    if (sum === 100) {
      U.id('FamOblittotalRatio').classList.remove('error');
      if (!noUpdt) {
        this.updateFamOblitHeirRatios();
      }
    } else {
      U.id('FamOblittotalRatio').classList.add('error');
    }
  },
  async addFamOblitHeir() {
    const inforObj = {
      relationship: [this.party1_name],
      organisation: 1,
    };
    this.party2_name && inforObj.relationship.push(this.party2_name);
    const data = await U.addCivillian(inforObj);
    if (data && data !== 'Canceled') {
      this.submitFamOblitHeir(data);
    }
  },
  async submitFamOblitHeir(data) {
    const vars = {};
    vars.application_uuid = this.application_uuid;
    if (data.user_uuid) {
      vars.heir_uuid = data.user_uuid;
    } else {
      vars.other = {
        name: data.orgName,
        contact: data.orgContact,
        notes: data.orgNotes,
      };
    }

    O.PREP([await O.mPOST('application/ins-obliteration-list', vars)]).then(async (res) => {
      const msg = res['application/ins-obliteration-list'].status;
      comp.toast(msg);

      const newHeirData = res['application/ins-obliteration-list'].data;
      this.buildFamOblitHeirListTable(newHeirData);
    });
  },
  async removeFamOblitHeir(heirID) {
    const method = 'application/rmv-obliteration-list';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.application_obliteration_uuid = heirID;

    O.PREP([await O.mPOST(method, vars)]).then(async (res) => {
      const newHeirData = res[method].data;
      const msg = res[method].status;
      comp.toast(msg);
      this.buildFamOblitHeirListTable(newHeirData);
    });
  },
  async updateFamOblitHeirRatios() {
    const ratios = document.querySelectorAll('.FamOblitHeirRatio');
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.heirs = [];

    ratios.forEach((radio) => {
      let dataObj = {};
      dataObj.heir_uuid = radio.id;
      let numericValue = radio.value.replace(/[^0-9.]/g, '');
      dataObj.share_ratio = numericValue;
      vars.heirs.push(dataObj);
    });
    O.PREP([await O.mPOST('application/ups-obliteration-list', vars)])
      .then(async (res) => {
        const newHeirData = res['application/ups-obliteration-list'].data;
        const msg = res['application/ups-obliteration-list'].status;
        comp.toast(msg);
      })
      .catch(console.error);
  },
  async saveNotes(table_index) {
    return new Promise(async (resolve, reject) => {
      try {
        const vars = {};
        vars.application_uuid = this.application_uuid;
        vars.table_index = table_index;
        vars.note = U.id(`heirNotes_${table_index}`).value || '';

        O.PREP([await O.mPOST('application/ups-heir-notes', vars)]).then(async () => {
          resolve();
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },

  //Save Page -------
  async savePage(nextPrev) {
    if (U.id('heirNotes_1')) {
      if (this.heir_notes_1 || U.id('heirNotes_1').value) {
        await this.saveNotes(1);
      }
    }
    if (this.heir_notes_2 || U.id('heirNotes_2').value) {
      await this.saveNotes(2);
    }
    if (this.heir_notes_3 || U.id('heirNotes_3').value) {
      await this.saveNotes(3);
    }

    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Heirs';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
