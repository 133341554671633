import U from '../js/utils';
import svg from '../js/svg';

export default async ({ elID, labelName, accept, requiredYN }) => {
  const div = `
    <div class="">
      <label for="${elID}" class="block text-gray-700 font-medium mb-2 text-left">${labelName}</label>
      <div class="relative w-full mx-auto items-center">
        <input id="${elID}" type="file" class="hidden" ${accept ? `accept="${accept}"` : ''} val="file" onchange="U.id('${elID}Text').textContent = this.files && this.files[0] ? this.files[0].name : 'Browse to upload'" ${requiredYN ? 'required' : ''} />
        <div onclick="U.id('${elID}').click()" 
             class="w-full px-4 py-2 border flex justify-center items-center transition-colors duration-200 cursor-pointer bg-primary-opacity hover:bg-secondary"
             role="button" 
             aria-label="Upload file">
          ${svg.uploadCloud().outerHTML}
          <span id="${elID}Text" class="ml-2">Browse to upload</span>
        </div>
        <span fb-for="${elID}" class="mt-1 text-sm text-red-600"></span>
      </div>
    </div>
  `;

  return U.createHTMLElementFromString(div);
};
