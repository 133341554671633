import svg from '../js/svg';

export default {
  application_uuid: null,
  relationships: null,
  party1_name: null,
  party2_name: null,
  async prepare() {
    this.application_uuid = thsPage.vars.application_uuid;
    const vars = {};
    vars.application_uuid = this.application_uuid;
    this.recipe.methods.push(await O.mPOST('application/get-gaurdian-detail', vars));
  },
  async render() {
    const rec = this.recipe.methods;

    let data = {};
    if (rec.length) {
      data = await O.PREP(rec);
    }
    await this.loadPage(data);
  },
  async loadPage(data) {
    const guardians = data['application/get-gaurdian-detail'].data.guardian_list;
    const guardians_yn = data['application/get-gaurdian-detail'].data.guardian_yn;
    const parties = data[`application/get-gaurdian-detail`].data.users;
    this.party1_name = parties.party1_name;
    this.party2_name = parties.party2_uuid ? parties.party2_name : null;
    // -------------------------------
    // Guardian Checkbox
    let no_guardians = await comp.toggle('no_guardians', guardians_yn, 'Guardians', `thsSubTab.toggleGuardian();`);
    U.id('guardian_yn').innerHTML = '';
    U.id(`guardian_yn`).appendChild(no_guardians);
    if (guardians_yn) {
      U.unhide('guardian_section');
    } else {
      U.hide('guardian_section');
    }

    // -------------------------------
    // Guardian Table
    await this.buildGTable(guardians);
  },
  async toggleGuardian() {
    // this.submitNewGuardian();
    // U.toggleHide('guardian_section');

    const vars = {};
    vars.guardian_yn = U.id('no_guardians').checked;
    vars.application_uuid = this.application_uuid;

    O.PREP([await O.mPOST('application/ups-gaurdian-detail', vars)])
      .then(async (res) => {
        comp.toast(res['application/ups-gaurdian-detail'].status);
        U.closeModal('civillianForm');
        this.render();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async buildGTable(guardians) {
    const table = U.id(`guardian_list`);

    const thead = U.divAtt('thead');
    const headRow = thead.insertRow();
    const nameH = headRow.insertCell(0);
    nameH.innerHTML = 'Name';
    const emailH = headRow.insertCell(1);
    emailH.innerHTML = 'Email address';
    const relParty1H = headRow.insertCell(2);
    relParty1H.innerHTML = 'Party 1';
    const relParty2H = headRow.insertCell(3);
    relParty2H.innerHTML = 'Party 2';
    const removeH = headRow.insertCell(4);
    removeH.innerHTML = 'Remove';
    thead.appendChild(headRow);

    table.innerHTML = '';
    table.appendChild(thead);
    const tbody = U.divAtt('tbody');
    table.appendChild(tbody);
    if (guardians.length) {
      for (let guardian of guardians) {
        const guardRow = tbody.insertRow();
        const name = guardRow.insertCell(0);
        name.innerHTML = `${guardian.f_name} ${guardian.s_name}`;
        const email = guardRow.insertCell(1);
        email.innerHTML = guardian.email_address;
        const rel_party1 = guardRow.insertCell(2);
        rel_party1.innerHTML = guardian.rel_party1;
        const rel_party2 = guardRow.insertCell(3);
        rel_party2.innerHTML = guardian.rel_party2;
        const removeIcon = guardRow.insertCell(4);
        const onclick = `thsSubTab.removeGuardian('${guardian.application_guardian_uuid}')`;
        const linkSVG = svg.trash(onclick);
        removeIcon.appendChild(linkSVG);
      }
    } else {
      //No Guadians ---
      const guardRow = tbody.insertRow();
      const noG = guardRow.insertCell(0);
      noG.innerHTML = 'No Guardians.';
      noG.colSpan = 5;
    }
  },
  async addGuardian() {
    let form = '';

    const grid2 = U.divAtt('div', 'class', 'res-grid-cols-2');
    grid2.id = 'newCivillian';

    const f_name = await comp.inputText(`f_name`, '', 'First Name(s)', 1);
    const s_name = await comp.inputText(`s_name`, '', 'Surname', 1);
    const IDpass = await comp.inputIDPassport('IDpass', true, '', 1, 'dob');
    grid2.appendChild(f_name);
    grid2.appendChild(s_name);
    grid2.appendChild(IDpass);

    const emailInput = await comp.inputEmail('email_address', '', 'Email', 1);
    grid2.appendChild(emailInput);

    const mobInput = await comp.inputTel('cell_nr', '', 'Mobile number', 1);
    grid2.appendChild(mobInput);

    const relDD1 = await comp.selectRelationship_v2('rel_party1', this.party1_name, 1);
    grid2.appendChild(relDD1);

    if (this.party2_name) {
      const relDD2 = await comp.selectRelationship_v2('rel_party2', this.party2_name, 0);
      grid2.appendChild(relDD2);
    }

    const grid4 = U.divAtt('div', 'class', 'res-grid-cols-1 col-span-2');
    const physHeading = U.divAtt('h6', 'class', 'mt-3');
    physHeading.innerHTML = 'Physical address';
    let phy_street = await comp.inputText(`physical_address`, '', 'Street address: ', 1);
    let phy_type = await comp.inputText(`physical_address_type`, '', 'Complex/Building (optional): ', 0);
    grid4.appendChild(physHeading);
    grid4.appendChild(phy_street);
    grid4.appendChild(phy_type);
    grid2.appendChild(grid4);
    const physGrid = U.divAtt('div', 'class', 'res-grid-cols-2 col-span-2');
    let phy_suburb = await comp.inputText(`physical_suburb`, '', 'Suburb: ', 0);
    let phy_town = await comp.inputText(`physical_town`, '', 'Town/City: ', 0);
    let phy_provDD = await comp.select(`physical_province`, communal.provinces, 'province_uuid', 'long_text', 'Province', 1);
    let phy_code = await comp.inputText(`physical_code`, '', 'Area code: ', 0);
    physGrid.appendChild(phy_suburb);
    physGrid.appendChild(phy_town);
    physGrid.appendChild(phy_provDD);
    physGrid.appendChild(phy_code);
    grid2.appendChild(physGrid);

    form += grid2.outerHTML;

    const div = `
    <div
      id="civillianForm"
      class="fixed inset-0 z-50 overflow-y-auto bg-opacity-50 bg-black ">

      <div class="card relative transform overflow-hidden">
      <h4>Information</h4>
    
      ${form}
    
      <div
      class="flex justify-center items-center" >
      <button id="cancelCivillian-btn" class="btn-outline mr-4" onclick="U.closeModal('civillianForm') ">Cancel</button>
      <button id="addCivillian-btn" onclick="thsSubTab.submitNewGuardian()">Add</button>

    </div>`;

    const passBox = U.createHTMLElementFromString(div);
    document.body.prepend(passBox);
  },
  async submitNewGuardian() {
    U.id('addCivillian-btn').disabled = true;

    const isValid = await U.validateForm('newCivillian');

    if (!isValid) {
      U.id('addCivillian-btn').disabled = false;
      return;
    }

    const vars = {};
    vars.guardian_yn = U.id('no_guardians').checked;
    vars.application_uuid = this.application_uuid;

    // Collect all input and select elements within the form
    const inputs = document.querySelectorAll('#civillianForm input, #civillianForm select');

    // Iterate over each input or select element
    inputs.forEach((element) => {
      // Add the id and its value to the vars object
      vars[element.id] = element.type === 'checkbox' ? element.checked : element.value;
    });

    vars[U.id('IDpass_toggle').checked ? 'id_nr' : 'passport_nr'] = U.id('IDpass').value;

    O.PREP([await O.mPOST('application/ups-gaurdian-detail-v2', vars)])
      .then(async (res) => {
        comp.toast(res['application/ups-gaurdian-detail-v2'].status);
        U.closeModal('civillianForm');
        this.render();
      })
      .catch((err) => {
        console.log(err);
      });
    U.id('addCivillian-btn').disabled = false;
  },
  async removeGuardian(application_guardian_uuid) {
    const method = 'application/rmv-gaurdian-detail';

    const vars = {};
    vars.application_guardian_uuid = application_guardian_uuid;
    vars.application_uuid = this.application_uuid;

    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        this.buildGTable(res[method].data.guardian_list);
        // O.changeSubTab('ADs_Guardian');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async savePage(nextPrev) {
    const method = 'application/val-app-page';
    const vars = {};
    vars.application_uuid = this.application_uuid;
    vars.page_name = 'ADs_Guardian';
    O.PREP([await O.mPOST(method, vars)])
      .then(async (res) => {
        comp.toast(res[method].status);
        thsPage.pageProgress(res[method].data, nextPrev);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
