import U from '../js/utils';

export default async (elID, valueData, requiredYN) => {
  return new Promise(async (resolve, reject) => {
    try {
      const div = `
      <div id="${elID}Div">
        <div class="input-group">
        <label id="${elID}_toggle_label" class="tgglLable" for="${elID}_toggle">ID Number</label>
          <input type="number" id="${elID}" name="${elID}" value="${valueData ? valueData : ''}" ${requiredYN ? 'required' : ''} val=idNumber />
          <span fb-for="${elID}"></span>
        </div>
      </div>
    `;
      resolve(U.createHTMLElementFromString(div));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
