import U from '../js/utils';

export default async (elID, isChecked, valueData, requiredYN, inputDOB) => {
  return new Promise(async (resolve, reject) => {
    try {
      const div = `
  <div id="${elID}Div">
   <div class="">
        <label class="switch">
            <input type="checkbox" id="${elID}_toggle" 
            ${isChecked ? 'checked' : ''} 
            onchange="U.IdPassToggle('${elID}')">
            <span class="toggle round"></span>
        </label>
        <label id="${elID}_toggle_label" class="tgglLable" for="${elID}_toggle">${isChecked ? 'ID Number' : 'Passport Number'}</label>
    </div>
    
    
     <div class="input-group">
      <input type="text" id="${elID}" name="${elID}" value="${valueData ? valueData : ''}" ${requiredYN ? 'required' : ''} val=${isChecked ? 'idNumber' : 'text'}
    ${inputDOB && `onkeyup="U.populateDOB(this.value, '${inputDOB}')"`}
 />
     <span fb-for="${elID}"></span>
    
    </div>
    </div>
    
    `;
      resolve(U.createHTMLElementFromString(div));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
